import * as THREE from 'three';
import {
    OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js';
import {
    AxesHelper
} from 'three';
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {
    TextureLoader
} from 'three/src/loaders/TextureLoader.js'



//Renderer und Resizing


const wuerfelUrl = new URL('../models/wuerfelanimation.glb',
    import.meta.url);

const ballsUrl = new URL('../models/ballsendev2.glb',
    import.meta.url);

const renderer = new THREE.WebGLRenderer({
    alpha: true
});

const clock = new THREE.Clock();
var speed = 2; 
var delta = 0;

renderer.setSize(window.innerWidth, window.innerHeight);

document.body.appendChild(renderer.domElement);

window.addEventListener('resize', function () {
    var height = window.innerHeight;
    var width = window.innerWidth;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
});

const scene = new THREE.Scene();


//Kamera

const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    800
);

camera.position.set(-3,2,3); // Set position like this

const orbit = new OrbitControls(camera, renderer.domElement);


orbit.update();





//Objekte

const assetLoader = new GLTFLoader();

let clip

let mixer;

assetLoader.load(ballsUrl.href, function (gltf) {
    const object = gltf.scene
    scene.add(object);
    mixer = new THREE.AnimationMixer(object);
    const clips = gltf.animations;
    clip = THREE.AnimationClip.findByName(clips,
        'ball');
    action = mixer.clipAction(clip);
    action.play();
    action.paused = 1
    object.castShadow = true;
    object.receiveShadow = true;
});


//Lichter

/* const licht = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
scene.add(licht); */

const directionalLight = new THREE.DirectionalLight( 0xffffff, 10);
directionalLight.castShadow = true;
scene.add( directionalLight );

/* const light = new THREE.AmbientLight( 0xffffff, 1 ); // soft white light
scene.add( light ); */

//Buttons

function init() {
    Buttons = document.getElementById('Button');

    Buttons.onclick = function StartAnimation() {

        animate();
    }
};

//Animation

function animate() {
    if (mixer)
        mixer.update(clock.getDelta());
    renderer.render(scene, camera);
};


renderer.shadowMap.enabled = true;

renderer.setAnimationLoop(animate);

renderer.setClearColor(0xffffff, 0);

console.log(renderer);